import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TranslateComponent from "./TranslateComponent";

const AnnouncementsListIntranet = ({ announcements, languageCode }) => {
	if (!announcements || announcements.length === 0) return null;

	return (
		<section
			className="py-5 pt-lg-7 pb-md-10 mb-md-7"
			style={{ backgroundColor: "#FFFFFF" }}
		>
			<Container>
				<Row className="justify-content-center">
					<Col xl={8}>
						<div
							style={{
								border: "1px solid #CEE4FF",
								borderRadius: "12px",
								overflow: "hidden",
							}}
						>
							<table style={{ width: "100%", borderCollapse: "collapse" }}>
								<thead>
									<tr style={{ backgroundColor: "#00304B", color: "#ffffff" }}>
										<th
											style={{
												textAlign: "left",
												padding: "1rem",

												borderRight: "1px solid #ffffff1a",
												borderRadius: "12px 0 0 0",
											}}
										>
											<TranslateComponent code={languageCode} text="Title" />
										</th>
										<th
											style={{
												textAlign: "left",
												padding: "1rem",

												borderRadius: "0 12px 0 0",
											}}
										>
											<TranslateComponent
												code={languageCode}
												text="Date Published"
											/>
										</th>
									</tr>
								</thead>
								<tbody>
									{announcements.map((announcement, idx) => {
										// Format the date to YYYY-MM-DD
										let formattedDate = announcement.file.date;
										const parsedDate = new Date(formattedDate);
										if (!isNaN(parsedDate)) {
											// Convert to YYYY-MM-DD
											formattedDate = parsedDate.toISOString().split("T")[0];
										}

										return (
											<tr
												key={idx}
												style={{
													backgroundColor:
														idx % 2 === 0 ? "#F0F7FF" : "#FFFFFF",
													borderBottom:
														idx !== announcements.length - 1
															? "1px solid #79C1FF"
															: "none",
												}}
											>
												<td
													style={{ padding: "1rem", verticalAlign: "middle" }}
												>
													<a
														href={announcement.file.url}
														target="_blank"
														rel="noopener noreferrer"
														style={{
															textDecoration: "none",
														}}
													>
														<TranslateComponent
															code={languageCode}
															text={announcement.file.title}
														/>
													</a>
												</td>
												<td
													style={{
														padding: "1rem",
														verticalAlign: "middle",
														color: "#0076AF",
													}}
												>
													{formattedDate}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AnnouncementsListIntranet;
